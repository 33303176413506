<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Grade Wise Fee
            <v-spacer></v-spacer>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                permission="fee-create"
                @action="
                  (form.dialog = true), $refs.form ? $refs.form.reset() : ''
                "
                v-if="grade"
                >New Fee
              </add-button>
            </transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :disabled="grades.length < 1"
                  :loading="fetchingGrade"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            sort-by="id"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head ? item.fee_head.title : "-" }}
                </td>
                <td class="text-xs-left">
                  <strong>{{ item.amount.currency() }}</strong>
                </td>
                <td class="text-xs-center">
                  <strong>{{ item.generate_bill == 1 ? "YES" : "NO" }}</strong>
                </td>
                <td class="text-xs-center">
                  <strong></strong>
                  <v-chip
                    color="primary"
                    label
                    small
                    v-if="item.fee_month.length === 12"
                  >
                    Every months</v-chip
                  >
                  <v-chip
                    style="margin-right:5px;margin-top:5px;margin-bottom:5px;"
                    v-else
                    :key="key"
                    v-for="(month, key) in item.fee_month"
                    label
                    small
                    >{{ fee_month_obj[month] }}</v-chip
                  >
                  &nbsp;
                </td>
                <!--<td class="text-xs-left"><strong>{{ (item.is_waive_off == 1) ?'YES':'NO' }}</strong>-->
                <!--</td>-->
                <td class="text-right">
                  <edit-button permission="fee-update" @agree="editFee(item)" />
                  <!--<delete-button permission="fee-delete" @agree="form.delete(item.id)"/>-->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <!--<v-pagination v-if="form.items.data.length > 0" v-model="pagination.page"-->
            <!--:length="form.items.meta.last_page"></v-pagination>-->
            <!--<v-spacer/>-->
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <br />
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-layout row wrap pa-3>
                <br />
                <v-flex xs12 sm12>
                  <v-select
                    :items="feeHeadOptions"
                    class="pa-0"
                    label="Select Fee Heads *"
                    v-model="form.fee_head_id"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Fee Head Field is required']"
                  />
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field
                    autocomplete="off"
                    label="Amount*"
                    required
                    class="pa-0"
                    v-model="form.amount"
                    name="amount"
                    :error-messages="form.errors.get('amount')"
                    type="number"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Amount Field is required']"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    required
                    :rules="[(v) => !!v.length || 'Month Field is required']"
                    v-model="form.fee_month"
                    :items="fee_month"
                    label="Select Months"
                    multiple
                    class="pt-0"
                    outlined
                    dense
                  >
                    <v-list-item slot="prepend-item" ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            form.fee_month.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-select>
                </v-flex>

                <v-flex xs10>
                  <v-checkbox color="blue" v-model="form.generate_bill">
                    <template v-slot:label>
                      <div style="color:#333;">
                        Fee will be
                        <strong>{{
                          form.generate_bill === true ? "included" : "excluded"
                        }}</strong>
                        in generated bill.
                        <p style="line-height: 10px">
                          <small style="color:#666;font-size: 10px;">
                            {{
                              form.generate_bill === true ? "Uncheck" : "Check"
                            }}
                            if you want to
                            {{
                              form.generate_bill === true
                                ? "exclude"
                                : "include"
                            }}
                            this fee amount on bill generation.
                          </small>
                        </p>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>

                <v-flex xs6 sm6>
                  <!--<v-checkbox v-model="form.generate_bill" label="Generate Bill"/>-->
                </v-flex>
                <!--<v-flex xs6 sm6>-->
                <!--<v-checkbox v-model="form.is_waive_off" label="Waive"/>-->
                <!--</v-flex>-->
              </v-layout>
              <!--<small>*indicates required field</small>-->
            </v-form>

            <!--<v-flex xs12>-->
            <!--<template>-->
            <!--<div style="color:red;">-->
            <!--<v-icon small color="orange">error_outline</v-icon>-->
            <!--<span style="color:orange"> Please Note</span>-->
            <!--<strong></strong>-->
            <!--<p style="line-height: 10px">-->
            <!--<small style="color:#666;font-size: 10px;">-->
            <!--The rank number provided should be a in hierarchical order <br> according to-->
            <!--the grade level.-->
            <!--</small>-->
            <!--</p>-->
            <!--</div>-->
            <!--</template>-->
            <!--</v-flex>-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        fee_head_id: "",
        gradeId: "",
        fee_month: [],
        batchId: "",
        amount: "",
        generate_bill: true,
        is_waive_off: 0,
      },
      "/api/fee"
    ),
    search: null,
    fetchingGrade: false,

    headers: [
      { text: "#", align: "left", value: "id", width: 20, sortable: false },
      {
        text: "Title",
        align: "left",
        value: "title",
        sortable: false,
        width: 270,
      },
      {
        text: "Amount",
        align: "left",
        value: "amount",
        sortable: false,
        width: 150,
      },
      {
        text: "Bill Generate",
        align: "left",
        value: "generate_bill",
        sortable: false,
        width: 120,
      },
      {
        text: "Months",
        align: "left",
        value: "months",
        sortable: false,
      },
      // {text: 'Waive', align: 'left', value: 'is_waive_off', sortable: false},
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 200,
      },
    ],
    grades: [],
    grade: "",
    fee_heads: [],
    fee_month: [
      { text: "Baisakh", value: "01" },
      { text: "Jestha", value: "02" },
      { text: "Ashadh", value: "03" },
      { text: "Shrawan", value: "04" },
      { text: "Bhadra", value: "05" },
      { text: "Ashwin", value: "06" },
      { text: "Kartik", value: "07" },
      { text: "Mangshir", value: "08" },
      { text: "Poush", value: "09" },
      { text: "Margh", value: "10" },
      { text: "Falgun", value: "11" },
      { text: "Chaitra", value: "12" },
    ],
    fee_month_obj: {
      "01": "Baisakh",
      "02": "Jestha",
      "03": "Ashadh",
      "04": "Shrawan",
      "05": "Bhadra",
      "06": "Ashwin",
      "07": "Kartik",
      "08": "Mangshir",
      "09": "Poush",
      "10": "Margh",
      "11": "Falgun",
      "12": "Chaitra",
      "13": "Baisakh (13)",
      "14": "Jestha (14)",
    },
  }),

  computed: {
    ...mapState(["batch"]),

    selectAllMonths() {
      return this.form.fee_month.length === this.fee_month.length;
    },
    selectFewMonths() {
      return this.form.fee_month.length > 0 && !this.selectAllMonths;
    },
    icon() {
      if (this.selectAllMonths) return "check_box";
      if (this.selectFewMonths) return "indeterminate_check_box";
      return "add_box";
    },
    feeHeadOptions() {
      let assignedFeeHeads = [];
      this.form.items.data.map(function(item) {
        assignedFeeHeads.push(item.fee_head_id);
      });
      return this.fee_heads.filter((item) => {
        return (
          assignedFeeHeads.indexOf(item.value) === -1 ||
          item.value === this.form.fee_head_id
        );
      });
    },
  },

  mounted() {
    this.getGrades();
    let b = this.$storage.sessionGet("_batch_se");
    if (b) b = JSON.parse(b);

    if (b && b.start === "2077-01-01") {
      this.fee_month.push({ text: "Baisakh (13)", value: "13" });
      this.fee_month.push({ text: "Jestha (14)", value: "14" });
    }
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    grade: function() {
      this.get();
      this.getFeeHead();
    },
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },

    editFee(item) {
      this.form.edit(item);
    },
    get(params) {
      if (this.grade) {
        let extraParams = "batchId=" + this.batch.id + "&gradeId=" + this.grade;
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        console.log(query);
        this.form.get(null, query).then(({ data }) => {
          // this.pagination.totalItems = data.meta.total
        });
      }
    },

    save() {
      this.form.batchId = this.batch.id;
      this.form.gradeId = this.grade;
      this.form.is_waive_off = this.form.is_waive_off ? 1 : 0;
      this.form.generate_bill = this.form.generate_bill ? 1 : 0;
      // if (this.form.is_waive_off) this.form.is_waive_off = 1;
      // if (this.form.generate_bill) this.form.generate_bill = 1;

      let new_fee = this.form.fee_month.map(function(data) {
        return data;
      });
      this.form.fee_month = new_fee.join(",");
      // console.log(this.form.fee_month);
      this.form
        .store()
        .then((data) => {})
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        });
    },

    getGrades() {
      this.fetchingGrade = true;
      this.$rest
        .get("/api/grades?rowsPerPage=50&descending=false")
        .then(({ data }) => {
          this.fetchingGrade = false;
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllMonths) {
          this.form.fee_month = [];
        } else {
          this.form.fee_month = this.fee_month.map((month) => {
            return month.value;
          });
        }
      });
    },
    getFeeHead() {
      this.$rest.get("/api/fee-head?rowsPerPage=100").then(({ data }) => {
        this.fee_heads = data.data.map((item) => {
          return { value: item.id, text: item.title };
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
